@use '@angular/material' as mat;
@import './albion-themes.scss';
@import './albion-theme-mixin.scss';

@include mat.elevation-classes();
@include mat.app-background();

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.albion-light-theme {
  @include mat.all-component-themes($albion-light-theme);
  @include albion-theme-mixin($albion-light-theme, false); //theme, isDarkTheme
}

.albion-dark-theme {
  @include mat.all-component-themes($albion-dark-theme);
  @include albion-theme-mixin($albion-dark-theme, true); //theme, isDarkTheme
}

.entitlements {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 3px 5px;
  border-radius: 4px;
  border: 2px solid var(--accent-color);
  box-shadow: 0 0 6px 0px var(--accent-color);
  background-color: rgba(255, 193, 7, 0.03);
  align-items: center;
  justify-content: center;
}

.entitlements p {
  margin: 0;
}

.mat-mdc-form-field {
  margin: 5px 5px 0px 5px;
}

.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.form-small-width {
  width: 90px;
}

.form-large-width {
  width: 300px;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.form-container-inline {
  display: inline-flex;
  flex-wrap: wrap;
}

.form-item-button {
  flex: 0 0 auto;
  margin: 15px 5px 0px 5px;
  align-self: flex-start;
}

.form-item-small {
  flex: 1 3 80px;
  min-width: 80px !important;
}

.form-item-medium {
  flex: 2 2 160px;
  min-width: 160px !important;
}

.form-item-large {
  flex: 3 1 300px;
  min-width: 300px !important;
}

//scrollbar
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-left: 1px solid var(--mat-sidenav-container-divider-color);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb:hover {
  border-left-width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}