@use '@angular/material' as mat;
@use 'sass:map';
@import './albion-theme-functions.scss';

@mixin albion-theme-mixin($theme, $is-dark) {
  $palettes: get-palettes($theme);
  $color-config: nth($palettes, 1);
  $primary-palette: nth($palettes, 2);
  $accent-palette: nth($palettes, 3);
  $warn-palette: nth($palettes, 4);
  $profit-palette: nth($palettes, 5);
  $loss-palette: nth($palettes, 6);
  $background-palette: nth($palettes, 7);
  $gray-palette: nth($palettes, 8);

  //variables
  --primary-color: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 'default'), mat.m2-get-color-from-palette($primary-palette, 'default'))};
  --primary-color-700: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 700), mat.m2-get-color-from-palette($primary-palette, 700))};
  --primary-color-600: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 600), mat.m2-get-color-from-palette($primary-palette, 600))};
  --primary-color-500: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 500), mat.m2-get-color-from-palette($primary-palette, 500))};
  --primary-color-200: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 200), mat.m2-get-color-from-palette($primary-palette, 200))};
  --primary-color-100: #{if($is-dark, mat.m2-get-color-from-palette($primary-palette, 100), mat.m2-get-color-from-palette($primary-palette, 100))};
  --primary-color-contrast: #{if(
 $is-dark,
  mat.m2-get-color-from-palette($primary-palette, 'default-contrast'),
  mat.m2-get-color-from-palette($primary-palette, 'default-contrast'))
}

;
--primary-color-light-contrast: #{if(
 $is-dark,
mat.m2-get-color-from-palette($primary-palette, 'lighter-contrast'),
mat.m2-get-color-from-palette($primary-palette, 'lighter-contrast'))
}

;

--accent-color: #{if($is-dark, mat.m2-get-color-from-palette($accent-palette, 'default'), mat.m2-get-color-from-palette($accent-palette, 'default'))};
--accent-color-dark: #{if($is-dark, mat.m2-get-color-from-palette($accent-palette, 'darker'), mat.m2-get-color-from-palette($accent-palette, 'darker'))};
--accent-color-contrast: #{if(
 $is-dark,
mat.m2-get-color-from-palette($accent-palette, 'default-contrast'),
mat.m2-get-color-from-palette($accent-palette, 'default-contrast'))
}

;

--warn-color: #{if($is-dark, mat.m2-get-color-from-palette($warn-palette, 'default'), mat.m2-get-color-from-palette($warn-palette, 'default'))};
--warn-color-contrast: #{if(
 $is-dark,
mat.m2-get-color-from-palette($warn-palette, 'default-contrast'),
mat.m2-get-color-from-palette($warn-palette, 'default-contrast'))
}

;

--profit-color: #{if($is-dark, mat.m2-get-color-from-palette($profit-palette, 'default'), mat.m2-get-color-from-palette($profit-palette, 'default'))};
--profit-color-contrast: #{if(
 $is-dark,
mat.m2-get-color-from-palette($profit-palette, 'default-contrast'),
mat.m2-get-color-from-palette($profit-palette, 'default-contrast'))
}

;

--loss-color: #{if($is-dark, mat.m2-get-color-from-palette($loss-palette, 'default'), mat.m2-get-color-from-palette($loss-palette, 'default'))};
--loss-color-contrast: #{if(
 $is-dark,
mat.m2-get-color-from-palette($loss-palette, 'default-contrast'),
mat.m2-get-color-from-palette($loss-palette, 'default-contrast'))
}

;

--background-color: #{mat.m2-get-color-from-palette($background-palette, 'background')};
--card-color: #{mat.m2-get-color-from-palette($background-palette, 'card')};
--chip-border-color: #{if($is-dark, mat.m2-get-color-from-palette($gray-palette, 400), mat.m2-get-color-from-palette($gray-palette, 600))};
--item-power-panel-background: #{if($is-dark, rgb(53, 53, 53), rgb(228, 222, 220))};

--dark-mode: #{$is-dark};

--contrast-weight: #{if($is-dark, 500, 700)};

--americas-color: #{if( $is-dark,var(--warn-color),var(--profit-color))};

--asia-color: #{if( $is-dark,var(--primary-color-200),var(--primary-color))};

--europe-color: #{if( $is-dark,var(--accent-color),var(--accent-color-dark))};

//sidenav active link
.mdc-list-item.active {
  @if $is-dark {
    background-color: var(--primary-color-600); // darker hue for dark theme
  }

  @else {
    background-color: var(--primary-color-100); // lighter hue for light theme
  }
}

.profit-color {
  @if $is-dark {
    color: mat.m2-get-color-from-palette($profit-palette, 400);
  }

  @else {
    color: mat.m2-get-color-from-palette($profit-palette, 800);
  }

  font-weight: 500;
}

.loss-color {
  @if $is-dark {
    color: mat.m2-get-color-from-palette($loss-palette, 400);
  }

  @else {
    color: mat.m2-get-color-from-palette($loss-palette, 700);
  }

  font-weight: 500;
}

.primary-background {
  @if $is-dark {
    background-color: mat.m2-get-color-from-palette($primary-palette, 700); // darker hue for dark theme
  }

  @else {
    background-color: mat.m2-get-color-from-palette($primary-palette, 600); // lighter hue for light theme
  }
}

.primary-background-light {
  @if $is-dark {
    background-color: mat.m2-get-color-from-palette($primary-palette, 500); // darker hue for dark theme
  }

  @else {
    background-color: mat.m2-get-color-from-palette($primary-palette, 400); // lighter hue for light theme
  }
}

.accent-background {
  @if $is-dark {
    background-color: mat.m2-get-color-from-palette($accent-palette, 800); // darker hue for dark theme
  }

  @else {
    background-color: mat.m2-get-color-from-palette($accent-palette, 600); // lighter hue for light theme
  }
}

.warn-background {
  @if $is-dark {
    background-color: mat.m2-get-color-from-palette($warn-palette, 600); // darker hue for dark theme
  }

  @else {
    background-color: mat.m2-get-color-from-palette($warn-palette, 600); // lighter hue for light theme
  }
}

a {
  color: mat.m2-get-color-from-palette($accent-palette, 600);
  font-weight: 500;
  text-decoration: none;
}

// Container class - add this to the parent element
.banner-container {
  position: relative;
  overflow: hidden;
}

// Banner class
.banner {
  position: absolute;
  top: 5%;
  background: var(--accent-color);
  color: black;
  width: 100%;
  text-align: center;
  padding: 0.3em;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: normal;
  transform-origin: center;
  box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.2);

  // Optional: Add z-index if needed
  z-index: 10;
}

.banner.right {
  right: -40%;
  left: auto;
  transform: rotate(45deg);
}

.banner.left {
  left: -40%;
  right: auto;
  transform: rotate(-45deg);
}

.banner.small {
  padding: 0.1em;
  font-size: 0.7em;
  font-weight: 400;
  letter-spacing: 0.1em;
}

// tab group
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-active-label-text-color: var(--accent-color);
  --mat-tab-header-active-ripple-color: var(--accent-color);
  --mat-tab-header-inactive-ripple-color: var(--accent-color);
  --mat-tab-header-active-focus-label-text-color: var(--accent-color);
  --mat-tab-header-active-hover-label-text-color: var(--accent-color);
  --mat-tab-header-active-focus-indicator-color: var(--accent-color);
  --mat-tab-header-active-hover-indicator-color: var(--accent-color);
  --mdc-tab-indicator-active-indicator-color: var(--accent-color);
}

// premium badge
.premium-badge {
  position: relative;

  &::after {
    content: '✨';
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 14px;
  }
}
}